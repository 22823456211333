import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  LString,
  type RemoteConfig as CoreRemoteConfig,
} from '@web-solutions/core/constants/remote-config';
import { InterButtonConfig } from '@web-solutions/core/payment/constants';
import { CoreSummarySection } from '@web-solutions/core/summary/types';

export enum SummarySection {
  MONEY_BACK = 'money_back',
  FEEDBACK_LIST = 'feedback_list',
  DOWNLOADED = 'downloaded',
};

export type MagicProgressType = 'circle' | 'line';

export interface RemoteConfig<FlowStep = string> extends CoreRemoteConfig<FlowStep> {
  landingStack: Array<SummarySection | CoreSummarySection>,
  landingButtons: Array<InterButtonConfig<SummarySection>>,

  headerTitle: LString,
  headerSubTitle: string,

  magicDurationLoad: number,
  magicTitle: string,
  magicStages: LString[],
  magicRoundCheckMark: boolean,
  magicType: MagicProgressType,

  emailPageTitle: string,
  emailPageSections: SummarySection[],
  emailPageBlurred: boolean,

  quizWelcomePage: {
    title: string,
    subtitle: string,
    button: string,
  },

  feedbacks: { title: string, text: string, imgKey?: string }[],
  feedbacksTitle: string;

  downloadedSection: {
    title: string,
    appstoreTitle: string,
    appstoreRating: string,
    playstoreTitle: string,
    playstoreRating: string,
  },
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,

  landingStack: [],
  landingButtons: [],

  headerTitle: '',
  headerSubTitle: '',

  magicDurationLoad: 8000,
  magicTitle: '',
  magicStages: [],
  magicRoundCheckMark: false,
  magicType: 'circle',

  emailPageTitle: '',
  emailPageSections: [],
  emailPageBlurred: false,

  quizWelcomePage: {
    title: '',
    subtitle: '',
    button: '',
  },

  feedbacks: [],
  feedbacksTitle: '',

  downloadedSection: {
    title: '',
    appstoreTitle: '',
    appstoreRating: '',
    playstoreTitle: '',
    playstoreRating: '',
  },
};
