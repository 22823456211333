import { ProductConfig } from "@web-solutions/core/constants/remote-config";

export enum PaymentSystem {
  STRIPE = 'stripe',
  RECURLY = 'recurly',
  SOLIDGATE = 'solidgate',
  PADDLE = 'paddle',
  PAYPAL = 'paypal',
};

export enum ModeApplePay {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  ENABLED_IF_WITH_CARD = 'enabled_if_with_card',
};

export enum ModeGooglePay {
  ENABLED_IF_PAYMENT_METHOD_PRESENT = 'enabled-if-payment-method-present',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
};

export enum ModeCardForm {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export type Period = 'P1W' | 'P2W' | 'P3W' | 'P1M' | 'P2M' | 'P3M' | 'P6M' | 'P1Y' | 'ONETIME';

export type Currency = "USD" | "AUD" | "CAD" | "EUR" | "BRL" | "GBP";

export const Currencies: Array<Currency> = ["USD", "AUD", "CAD", "EUR", "BRL", "GBP"];

export interface Product {
  id: string,
  amount: string,
  currency: Currency,
  period: Period,
  trial_period_days: string,
  trial_price_amount: string,
  currencies: {
    country: string | string[],
    currency: Currency,
    amount: string,
    trial_price_amount: string,
  }[],
}

export interface ProductInfo extends Product, Omit<ProductConfig, 'id'> {
  trialDays: number,
  trialPrice: string,
  trialDayPrice: string,
  trialPriceAmount: number,
  prevTrialPriceAmount: number,
  prevTrialPrice: string,
  savedTrialPrice: string,
  isTrial: boolean,
  isIntroductory: boolean,
  price: string,
  priceText: string,
  priceAmount: number,
  prevPriceAmount: number,
  prevPrice: string,
  savedPrice: string,
  dayPriceAmount: number,
  dayPrice: string,
  prevDayPriceAmount: number,
  prevDayPrice: string,
  savedDayPrice: string,
  weekPriceAmount: number,
  weekPrice: string,
  prevWeekPriceAmount: number,
  prevWeekPrice: string,
  savedWeekPrice: string,
  expireDate: string,
  expireAt?: number,
  introExpireDate: string,
  isOneTimePurchase: boolean,
  vat: string,
  vatPrice: string,
  vatPriceCombo: string,
  vatTrial: string,
  vatTrialPrice: string,
  vatTrialPriceCombo: string,
  weekVat: string,
  vatWeekPrice: string,
  vatWeekPriceCombo: string,
  dayVat: string,
  vatDayPrice: string,
  vatDayPriceCombo: string,
  minutesAmount?: number,
}

export * from './prices'

export interface AlternativePaymentOrder {
  order: {
    order_id: string,
  },
}

export interface SolidgatePayPalOrder extends AlternativePaymentOrder {
  pay_form: {
    script_url: string
  }
}

export interface SolidgateMercadoPagoOrder extends AlternativePaymentOrder {
  pay_form: {
    return_url: string
  }
}
