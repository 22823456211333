import { ThunkAction } from 'redux-thunk';

import { setPending } from '@web-solutions/base-app/store/app/actions';

import { urlToFile } from '@web-solutions/core/utils/url-sync';

import { getProfile, updateProfile } from "src/api/profile";
import { fetchColorTypeByFaceImage } from "src/api/ai";

import { QUIZ } from 'src/constants/routes';

import type { UpdateProfileData } from 'src/api/profile/types';

import type { RootState } from '../types'

export { processEmail } from '@web-solutions/base-app/store/profile/actions';

export const updateProfileData = ({ withLoader = true, withImage = true }: { withLoader?: boolean, withImage?: boolean } = {}): ThunkAction<Promise<void>, RootState, unknown, any> =>
  async (dispatch, getState) => {
    const {
      quiz: { selectedAnswers },
      faceReading: { faceImg }
    } = getState();

    if (withLoader) {
      dispatch(setPending(true));
    }

    try {
      let profileData: UpdateProfileData['profile_data'] = {
        color_type: null,
        photo_url: null,
        body_shape: selectedAnswers?.[QUIZ.BODY_SHAPE]?.[0],
        eyes_color: selectedAnswers?.[QUIZ.EYES_COLOR]?.[0],
        hair_color: selectedAnswers?.[QUIZ.HAIR_COLOR]?.[0],
        skin_tone: selectedAnswers?.[QUIZ.SKIN_TONE]?.[0]
      }

      if (withImage) {
        try {
          const faceImgFile = faceImg ? await urlToFile(faceImg, 'face.png', 'image/png') : null

          if (faceImgFile) {
            const colorTypeResponse = await fetchColorTypeByFaceImage(faceImgFile)

            profileData = {
              ...profileData,
              color_type: colorTypeResponse?.color_type ?? null,
              photo_url: colorTypeResponse?.photoUrl ?? null,
            }
          }
        } catch (err) {
          console.log('[ERROR FETCH COLOR TYPE]', err);
        }
      }

      const profileResponse = await getProfile();

      await updateProfile({
        id: profileResponse?.data?.id as string,
        data: {
          profile_data: profileData
        }
      });
    } catch (err) {
      console.log('[ERROR UPDATE PROFILE]', err);
      throw err;
    } finally {
      console.log(`DataFinish: ${new Date()}`);

      if (withLoader) {
        dispatch(setPending(false));
      }
    }
  };
