import { ImagesMap } from 'core/interfaces/images';

// common
import OTHER_SMALL_1X from './common/other_small.webp';
import OTHER_SMALL_2X from './common/other_small@2x.webp';
import OTHER_SMALL_3X from './common/other_small@3x.webp';

import COOL_1X from './common/cool.webp';
import COOL_2X from './common/cool@2x.webp';
import COOL_3X from './common/cool@3x.webp';

import CUTE_1X from './common/cute.webp';
import CUTE_2X from './common/cute@2x.webp';
import CUTE_3X from './common/cute@3x.webp';

import LOVE_1X from './common/love.webp';
import LOVE_2X from './common/love@2x.webp';
import LOVE_3X from './common/love@3x.webp';

import OK_1X from './common/ok.webp';
import OK_2X from './common/ok@2x.webp';
import OK_3X from './common/ok@3x.webp';

// transitions
import TRANS_WHICH_SUIT_1X from './trans-which-suit/suit.webp';
import TRANS_WHICH_SUIT_2X from './trans-which-suit/suit@2x.webp';
import TRANS_WHICH_SUIT_3X from './trans-which-suit/suit@3x.webp';

import TRANS_NOTHING_TO_WEAR_1X from './trans-nothing-to-wear/nothing.webp';
import TRANS_NOTHING_TO_WEAR_2X from './trans-nothing-to-wear/nothing@2x.webp';
import TRANS_NOTHING_TO_WEAR_3X from './trans-nothing-to-wear/nothing@3x.webp';

import TRANS_WARDROBE_REGRETS_1X from './trans-wardrobe-regrets/regrets.webp';
import TRANS_WARDROBE_REGRETS_2X from './trans-wardrobe-regrets/regrets@2x.webp';
import TRANS_WARDROBE_REGRETS_3X from './trans-wardrobe-regrets/regrets@3x.webp';

import TRANS_STYLE_GAME_1X from './trans-style-game/game.webp';
import TRANS_STYLE_GAME_2X from './trans-style-game/game@2x.webp';
import TRANS_STYLE_GAME_3X from './trans-style-game/game@3x.webp';

import TRANS_COLOR_TYPE_1X from './trans-color-type/type.webp';
import TRANS_COLOR_TYPE_2X from './trans-color-type/type@2x.webp';
import TRANS_COLOR_TYPE_3X from './trans-color-type/type@3x.webp';

import TRANS_STYLE_FORMULA_1X from './trans-style-formula/formula.webp';
import TRANS_STYLE_FORMULA_2X from './trans-style-formula/formula@2x.webp';
import TRANS_STYLE_FORMULA_3X from './trans-style-formula/formula@3x.webp';

import TRANS_EVERY_OUTFIT_1X from './trans-every-outfit/outfit.webp';
import TRANS_EVERY_OUTFIT_2X from './trans-every-outfit/outfit@2x.webp';
import TRANS_EVERY_OUTFIT_3X from './trans-every-outfit/outfit@3x.webp';

import TRANS_WORK_FOR_YOU_1X from './trans_work_for_you/colors.webp';
import TRANS_WORK_FOR_YOU_2X from './trans_work_for_you/colors@2x.webp';
import TRANS_WORK_FOR_YOU_3X from './trans_work_for_you/colors@3x.webp';

import TRANS_NOT_ALONE_1X from './trans_you_not_alone/not_alone.webp';
import TRANS_NOT_ALONE_2X from './trans_you_not_alone/not_alone@2x.webp';
import TRANS_NOT_ALONE_3X from './trans_you_not_alone/not_alone@3x.webp';

import TRANS_CAPSULE_WARDROBE_1X from './trans_capsule_wardrobe/capsule_wardrobe.webp';
import TRANS_CAPSULE_WARDROBE_2X from './trans_capsule_wardrobe/capsule_wardrobe@2x.webp';
import TRANS_CAPSULE_WARDROBE_3X from './trans_capsule_wardrobe/capsule_wardrobe@3x.webp';

import TRANS_LOOK_BEST_1X from './trans_look_best/look_best.webp';
import TRANS_LOOK_BEST_2X from './trans_look_best/look_best@2x.webp';
import TRANS_LOOK_BEST_3X from './trans_look_best/look_best@3x.webp';

import TRANS_PERFECT_CUTS_1X from './trans_perfect_cuts/perfect_cuts.webp';
import TRANS_PERFECT_CUTS_2X from './trans_perfect_cuts/perfect_cuts@2x.webp';
import TRANS_PERFECT_CUTS_3X from './trans_perfect_cuts/perfect_cuts@3x.webp';

// quiz
import PERSONALITY_1X from './main-goal/personality.webp';
import PERSONALITY_2X from './main-goal/personality@2x.webp';
import PERSONALITY_3X from './main-goal/personality@3x.webp';

import THROUGH_DAY_1X from './main-goal/through_day.webp';
import THROUGH_DAY_2X from './main-goal/through_day@2x.webp';
import THROUGH_DAY_3X from './main-goal/through_day@3x.webp';

import BODY_TYPE_1X from './biggest-challenges/body_type.webp';
import BODY_TYPE_2X from './biggest-challenges/body_type@2x.webp';
import BODY_TYPE_3X from './biggest-challenges/body_type@3x.webp';

import EVENTS_1X from './biggest-challenges/events.webp';
import EVENTS_2X from './biggest-challenges/events@2x.webp';
import EVENTS_3X from './biggest-challenges/events@3x.webp';

import OUTFITS_1X from './biggest-challenges/outfits.webp';
import OUTFITS_2X from './biggest-challenges/outfits@2x.webp';
import OUTFITS_3X from './biggest-challenges/outfits@3x.webp';

import PALETTE_1X from './biggest-challenges/palette.webp';
import PALETTE_2X from './biggest-challenges/palette@2x.webp';
import PALETTE_3X from './biggest-challenges/palette@3x.webp';

import PERSONAL_STYLE_1X from './biggest-challenges/personal_style.webp';
import PERSONAL_STYLE_2X from './biggest-challenges/personal_style@2x.webp';
import PERSONAL_STYLE_3X from './biggest-challenges/personal_style@3x.webp';

import TRENDS_1X from './biggest-challenges/trends.webp';
import TRENDS_2X from './biggest-challenges/trends@2x.webp';
import TRENDS_3X from './biggest-challenges/trends@3x.webp';

import CASUAL_1X from './struggle_picking_out/casual.webp';
import CASUAL_2X from './struggle_picking_out/casual@2x.webp';
import CASUAL_3X from './struggle_picking_out/casual@3x.webp';

import EVERYDAY_1X from './struggle_picking_out/everyday.webp';
import EVERYDAY_2X from './struggle_picking_out/everyday@2x.webp';
import EVERYDAY_3X from './struggle_picking_out/everyday@3x.webp';

import NONE_1X from './struggle_picking_out/none.webp';
import NONE_2X from './struggle_picking_out/none@2x.webp';
import NONE_3X from './struggle_picking_out/none@3x.webp';

import SPECIAL_1X from './struggle_picking_out/special.webp';
import SPECIAL_2X from './struggle_picking_out/special@2x.webp';
import SPECIAL_3X from './struggle_picking_out/special@3x.webp';

import VACATIONS_1X from './struggle_picking_out/vacations.webp';
import VACATIONS_2X from './struggle_picking_out/vacations@2x.webp';
import VACATIONS_3X from './struggle_picking_out/vacations@3x.webp';

import WORK_1X from './struggle_picking_out/work.webp';
import WORK_2X from './struggle_picking_out/work@2x.webp';
import WORK_3X from './struggle_picking_out/work@3x.webp';

import JEANS_1X from './most-comfortable/jeans.webp';
import JEANS_2X from './most-comfortable/jeans@2x.webp';
import JEANS_3X from './most-comfortable/jeans@3x.webp';

import LEGGINGS_1X from './most-comfortable/leggings.webp';
import LEGGINGS_2X from './most-comfortable/leggings@2x.webp';
import LEGGINGS_3X from './most-comfortable/leggings@3x.webp';

import BIG_NONE_1X from './most-comfortable/none.webp';
import BIG_NONE_2X from './most-comfortable/none@2x.webp';
import BIG_NONE_3X from './most-comfortable/none@3x.webp';

import SHORTS_1X from './most-comfortable/shorts.webp';
import SHORTS_2X from './most-comfortable/shorts@2x.webp';
import SHORTS_3X from './most-comfortable/shorts@3x.webp';

import SKIRTS_1X from './most-comfortable/skirts.webp';
import SKIRTS_2X from './most-comfortable/skirts@2x.webp';
import SKIRTS_3X from './most-comfortable/skirts@3x.webp';

import SUITS_1X from './most-comfortable/suits.webp';
import SUITS_2X from './most-comfortable/suits@2x.webp';
import SUITS_3X from './most-comfortable/suits@3x.webp';

import SWEATPANTS_1X from './most-comfortable/sweatpants.webp';
import SWEATPANTS_2X from './most-comfortable/sweatpants@2x.webp';
import SWEATPANTS_3X from './most-comfortable/sweatpants@3x.webp';

import BOHEMIAN_1X from './most-resonates/bohemian.webp';
import BOHEMIAN_2X from './most-resonates/bohemian@2x.webp';
import BOHEMIAN_3X from './most-resonates/bohemian@3x.webp';

import BUSINESS_1X from './most-resonates/business.webp';
import BUSINESS_2X from './most-resonates/business@2x.webp';
import BUSINESS_3X from './most-resonates/business@3x.webp';

import CASUAL_STYLE_1X from './most-resonates/casual_style.webp';
import CASUAL_STYLE_2X from './most-resonates/casual_style@2x.webp';
import CASUAL_STYLE_3X from './most-resonates/casual_style@3x.webp';

import COLORFUL_1X from './most-resonates/colorful.webp';
import COLORFUL_2X from './most-resonates/colorful@2x.webp';
import COLORFUL_3X from './most-resonates/colorful@3x.webp';

import ELEGANT_1X from './most-resonates/elegant.webp';
import ELEGANT_2X from './most-resonates/elegant@2x.webp';
import ELEGANT_3X from './most-resonates/elegant@3x.webp';

import GLAMOROUS_1X from './most-resonates/glamorous.webp';
import GLAMOROUS_2X from './most-resonates/glamorous@2x.webp';
import GLAMOROUS_3X from './most-resonates/glamorous@3x.webp';

import GRUNGE_1X from './most-resonates/grunge.webp';
import GRUNGE_2X from './most-resonates/grunge@2x.webp';
import GRUNGE_3X from './most-resonates/grunge@3x.webp';

import MINIMALISTIC_1X from './most-resonates/minimalistic.webp';
import MINIMALISTIC_2X from './most-resonates/minimalistic@2x.webp';
import MINIMALISTIC_3X from './most-resonates/minimalistic@3x.webp';

import OTHER_STYLE_1X from './most-resonates/other_style.webp';
import OTHER_STYLE_2X from './most-resonates/other_style@2x.webp';
import OTHER_STYLE_3X from './most-resonates/other_style@3x.webp';

import PREPPY_1X from './most-resonates/preppy.webp';
import PREPPY_2X from './most-resonates/preppy@2x.webp';
import PREPPY_3X from './most-resonates/preppy@3x.webp';

import SPORTY_1X from './most-resonates/sporty.webp';
import SPORTY_2X from './most-resonates/sporty@2x.webp';
import SPORTY_3X from './most-resonates/sporty@3x.webp';

import POWERFUL_1X from './outfit_you_love/powerful.webp';
import POWERFUL_2X from './outfit_you_love/powerful@2x.webp';
import POWERFUL_3X from './outfit_you_love/powerful@3x.webp';

import DIFFERENT_1X from './eyes-color/different.webp';
import DIFFERENT_2X from './eyes-color/different@2x.webp';
import DIFFERENT_3X from './eyes-color/different@3x.webp';

import APPLE_1X from './body-shape/apple.webp';
import APPLE_2X from './body-shape/apple@2x.webp';
import APPLE_3X from './body-shape/apple@3x.webp';

import HOURGLASS_1X from './body-shape/hourglass.webp';
import HOURGLASS_2X from './body-shape/hourglass@2x.webp';
import HOURGLASS_3X from './body-shape/hourglass@3x.webp';

import PEAR_1X from './body-shape/pear.webp';
import PEAR_2X from './body-shape/pear@2x.webp';
import PEAR_3X from './body-shape/pear@3x.webp';

import RECTANGLE_1X from './body-shape/rectangle.webp';
import RECTANGLE_2X from './body-shape/rectangle@2x.webp';
import RECTANGLE_3X from './body-shape/rectangle@3x.webp';

import TRIANGLE_1X from './body-shape/triangle.webp';
import TRIANGLE_2X from './body-shape/triangle@2x.webp';
import TRIANGLE_3X from './body-shape/triangle@3x.webp';

import BUDGET_1X from './wardrobe/budget.webp';
import BUDGET_2X from './wardrobe/budget@2x.webp';
import BUDGET_3X from './wardrobe/budget@3x.webp';

import MORNING_1X from './wardrobe/morning.webp';
import MORNING_2X from './wardrobe/morning@2x.webp';
import MORNING_3X from './wardrobe/morning@3x.webp';

import SHOPPING_1X from './wardrobe/shopping.webp';
import SHOPPING_2X from './wardrobe/shopping@2x.webp';
import SHOPPING_3X from './wardrobe/shopping@3x.webp';

import STYLE_1X from './wardrobe/style.webp';
import STYLE_2X from './wardrobe/style@2x.webp';
import STYLE_3X from './wardrobe/style@3x.webp';

import * as STRUGGLE_PICKING_LIGHT from './struggle_picking_out/light'

import * as MOST_COMFORTABLE_LIGHT from './most-comfortable/light'

import * as BODY_SHAPE_LIGHT from './body-shape/light'

import {
  SMALL_OTHER_BUTTON_1X,
  SMALL_OTHER_BUTTON_2X,
  SMALL_OTHER_BUTTON_3X,

  SMALL_NONE_BUTTON_1X,
  SMALL_NONE_BUTTON_2X,
  SMALL_NONE_BUTTON_3X,
} from './common'


export const IMAGES: ImagesMap = {
  // common
  OTHER_SMALL: {
    src: OTHER_SMALL_1X,
    srcSet: `${OTHER_SMALL_1X}, ${OTHER_SMALL_2X} 2x, ${OTHER_SMALL_3X} 3x`
  },
  COOL: {
    src: COOL_1X,
    srcSet: `${COOL_1X}, ${COOL_2X} 2x, ${COOL_3X} 3x`
  },
  CUTE: {
    src: CUTE_1X,
    srcSet: `${CUTE_1X}, ${CUTE_2X} 2x, ${CUTE_3X} 3x`
  },
  LOVE: {
    src: LOVE_1X,
    srcSet: `${LOVE_1X}, ${LOVE_2X} 2x, ${LOVE_3X} 3x`
  },
  OK: {
    src: OK_1X,
    srcSet: `${OK_1X}, ${OK_2X} 2x, ${OK_3X} 3x`
  },
  // transitions
  TRANS_WHICH_SUIT: {
    src: TRANS_WHICH_SUIT_1X,
    srcSet: `${TRANS_WHICH_SUIT_1X}, ${TRANS_WHICH_SUIT_2X} 2x, ${TRANS_WHICH_SUIT_3X} 3x`
  },
  TRANS_NOTHING_TO_WEAR: {
    src: TRANS_NOTHING_TO_WEAR_1X,
    srcSet: `${TRANS_NOTHING_TO_WEAR_1X}, ${TRANS_NOTHING_TO_WEAR_2X} 2x, ${TRANS_NOTHING_TO_WEAR_3X} 3x`
  },
  TRANS_WARDROBE_REGRETS: {
    src: TRANS_WARDROBE_REGRETS_1X,
    srcSet: `${TRANS_WARDROBE_REGRETS_1X}, ${TRANS_WARDROBE_REGRETS_2X} 2x, ${TRANS_WARDROBE_REGRETS_3X} 3x`
  },
  TRANS_STYLE_GAME: {
    src: TRANS_STYLE_GAME_1X,
    srcSet: `${TRANS_STYLE_GAME_1X}, ${TRANS_STYLE_GAME_2X} 2x, ${TRANS_STYLE_GAME_3X} 3x`
  },
  TRANS_COLOR_TYPE: {
    src: TRANS_COLOR_TYPE_1X,
    srcSet: `${TRANS_COLOR_TYPE_1X}, ${TRANS_COLOR_TYPE_2X} 2x, ${TRANS_COLOR_TYPE_3X} 3x`
  },
  TRANS_STYLE_FORMULA: {
    src: TRANS_STYLE_FORMULA_1X,
    srcSet: `${TRANS_STYLE_FORMULA_1X}, ${TRANS_STYLE_FORMULA_2X} 2x, ${TRANS_STYLE_FORMULA_3X} 3x`
  },
  TRANS_EVERY_OUTFIT: {
    src: TRANS_EVERY_OUTFIT_1X,
    srcSet: `${TRANS_EVERY_OUTFIT_1X}, ${TRANS_EVERY_OUTFIT_2X} 2x, ${TRANS_EVERY_OUTFIT_3X} 3x`
  },
  TRANS_WORK_FOR_YOU: {
    src: TRANS_WORK_FOR_YOU_1X,
    srcSet: `${TRANS_WORK_FOR_YOU_1X}, ${TRANS_WORK_FOR_YOU_2X} 2x, ${TRANS_WORK_FOR_YOU_3X} 3x`
  },
  TRANS_YOU_NOT_ALONE: {
    src: TRANS_NOT_ALONE_1X,
    srcSet: `${TRANS_NOT_ALONE_1X}, ${TRANS_NOT_ALONE_2X} 2x, ${TRANS_NOT_ALONE_3X} 3x`
  },
  TRANS_CAPSULE_WARDROBE: {
    src: TRANS_CAPSULE_WARDROBE_1X,
    srcSet: `${TRANS_CAPSULE_WARDROBE_1X}, ${TRANS_CAPSULE_WARDROBE_2X} 2x, ${TRANS_CAPSULE_WARDROBE_3X} 3x`
  },
  TRANS_LOOK_BEST: {
    src: TRANS_LOOK_BEST_1X,
    srcSet: `${TRANS_LOOK_BEST_1X}, ${TRANS_LOOK_BEST_2X} 2x, ${TRANS_LOOK_BEST_3X} 3x`
  },
  TRANS_PERFECT_CUTS: {
    src: TRANS_PERFECT_CUTS_1X,
    srcSet: `${TRANS_PERFECT_CUTS_1X}, ${TRANS_PERFECT_CUTS_2X} 2x, ${TRANS_PERFECT_CUTS_3X} 3x`
  },
  // quiz
  PERSONALITY: {
    src: PERSONALITY_1X,
    srcSet: `${PERSONALITY_1X}, ${PERSONALITY_2X} 2x, ${PERSONALITY_3X} 3x`
  },
  THROUGH_DAY: {
    src: THROUGH_DAY_1X,
    srcSet: `${THROUGH_DAY_1X}, ${THROUGH_DAY_2X} 2x, ${THROUGH_DAY_3X} 3x`
  },
  BODY_TYPE: {
    src: BODY_TYPE_1X,
    srcSet: `${BODY_TYPE_1X}, ${BODY_TYPE_2X} 2x, ${BODY_TYPE_3X} 3x`
  },
  EVENTS: {
    src: EVENTS_1X,
    srcSet: `${EVENTS_1X}, ${EVENTS_2X} 2x, ${EVENTS_3X} 3x`
  },
  OUTFITS: {
    src: OUTFITS_1X,
    srcSet: `${OUTFITS_1X}, ${OUTFITS_2X} 2x, ${OUTFITS_3X} 3x`
  },
  PALETTE: {
    src: PALETTE_1X,
    srcSet: `${PALETTE_1X}, ${PALETTE_2X} 2x, ${PALETTE_3X} 3x`
  },
  PERSONAL_STYLE: {
    src: PERSONAL_STYLE_1X,
    srcSet: `${PERSONAL_STYLE_1X}, ${PERSONAL_STYLE_2X} 2x, ${PERSONAL_STYLE_3X} 3x`
  },
  TRENDS: {
    src: TRENDS_1X,
    srcSet: `${TRENDS_1X}, ${TRENDS_2X} 2x, ${TRENDS_3X} 3x`
  },
  CASUAL: {
    src: CASUAL_1X,
    srcSet: `${CASUAL_1X}, ${CASUAL_2X} 2x, ${CASUAL_3X} 3x`
  },
  EVERYDAY: {
    src: EVERYDAY_1X,
    srcSet: `${EVERYDAY_1X}, ${EVERYDAY_2X} 2x, ${EVERYDAY_3X} 3x`
  },
  NONE: {
    src: NONE_1X,
    srcSet: `${NONE_1X}, ${NONE_2X} 2x, ${NONE_3X} 3x`
  },
  SPECIAL: {
    src: SPECIAL_1X,
    srcSet: `${SPECIAL_1X}, ${SPECIAL_2X} 2x, ${SPECIAL_3X} 3x`
  },
  VACATIONS: {
    src: VACATIONS_1X,
    srcSet: `${VACATIONS_1X}, ${VACATIONS_2X} 2x, ${VACATIONS_3X} 3x`
  },
  WORK: {
    src: WORK_1X,
    srcSet: `${WORK_1X}, ${WORK_2X} 2x, ${WORK_3X} 3x`
  },
  JEANS: {
    src: JEANS_1X,
    srcSet: `${JEANS_1X}, ${JEANS_2X} 2x, ${JEANS_3X} 3x`
  },
  LEGGINGS: {
    src: LEGGINGS_1X,
    srcSet: `${LEGGINGS_1X}, ${LEGGINGS_2X} 2x, ${LEGGINGS_3X} 3x`
  },
  BIG_NONE: {
    src: BIG_NONE_1X,
    srcSet: `${BIG_NONE_1X}, ${BIG_NONE_2X} 2x, ${BIG_NONE_3X} 3x`
  },
  SHORTS: {
    src: SHORTS_1X,
    srcSet: `${SHORTS_1X}, ${SHORTS_2X} 2x, ${SHORTS_3X} 3x`
  },
  SKIRTS: {
    src: SKIRTS_1X,
    srcSet: `${SKIRTS_1X}, ${SKIRTS_2X} 2x, ${SKIRTS_3X} 3x`
  },
  SUITS: {
    src: SUITS_1X,
    srcSet: `${SUITS_1X}, ${SUITS_2X} 2x, ${SUITS_3X} 3x`
  },
  SWEATPANTS: {
    src: SWEATPANTS_1X,
    srcSet: `${SWEATPANTS_1X}, ${SWEATPANTS_2X} 2x, ${SWEATPANTS_3X} 3x`
  },
  BOHEMIAN: {
    src: BOHEMIAN_1X,
    srcSet: `${BOHEMIAN_1X}, ${BOHEMIAN_2X} 2x, ${BOHEMIAN_3X} 3x`
  },
  BUSINESS: {
    src: BUSINESS_1X,
    srcSet: `${BUSINESS_1X}, ${BUSINESS_2X} 2x, ${BUSINESS_3X} 3x`
  },
  CASUAL_STYLE: {
    src: CASUAL_STYLE_1X,
    srcSet: `${CASUAL_STYLE_1X}, ${CASUAL_STYLE_2X} 2x, ${CASUAL_STYLE_3X} 3x`
  },
  COLORFUL: {
    src: COLORFUL_1X,
    srcSet: `${COLORFUL_1X}, ${COLORFUL_2X} 2x, ${COLORFUL_3X} 3x`
  },
  ELEGANT: {
    src: ELEGANT_1X,
    srcSet: `${ELEGANT_1X}, ${ELEGANT_2X} 2x, ${ELEGANT_3X} 3x`
  },
  GLAMOROUS: {
    src: GLAMOROUS_1X,
    srcSet: `${GLAMOROUS_1X}, ${GLAMOROUS_2X} 2x, ${GLAMOROUS_3X} 3x`
  },
  GRUNGE: {
    src: GRUNGE_1X,
    srcSet: `${GRUNGE_1X}, ${GRUNGE_2X} 2x, ${GRUNGE_3X} 3x`
  },
  MINIMALISTIC: {
    src: MINIMALISTIC_1X,
    srcSet: `${MINIMALISTIC_1X}, ${MINIMALISTIC_2X} 2x, ${MINIMALISTIC_3X} 3x`
  },
  OTHER_STYLE: {
    src: OTHER_STYLE_1X,
    srcSet: `${OTHER_STYLE_1X}, ${OTHER_STYLE_2X} 2x, ${OTHER_STYLE_3X} 3x`
  },
  PREPPY: {
    src: PREPPY_1X,
    srcSet: `${PREPPY_1X}, ${PREPPY_2X} 2x, ${PREPPY_3X} 3x`
  },
  SPORTY: {
    src: SPORTY_1X,
    srcSet: `${SPORTY_1X}, ${SPORTY_2X} 2x, ${SPORTY_3X} 3x`
  },
  POWERFUL: {
    src: POWERFUL_1X,
    srcSet: `${POWERFUL_1X}, ${POWERFUL_2X} 2x, ${POWERFUL_3X} 3x`
  },
  DIFFERENT: {
    src: DIFFERENT_1X,
    srcSet: `${DIFFERENT_1X}, ${DIFFERENT_2X} 2x, ${DIFFERENT_3X} 3x`
  },
  APPLE: {
    src: APPLE_1X,
    srcSet: `${APPLE_1X}, ${APPLE_2X} 2x, ${APPLE_3X} 3x`
  },
  HOURGLASS: {
    src: HOURGLASS_1X,
    srcSet: `${HOURGLASS_1X}, ${HOURGLASS_2X} 2x, ${HOURGLASS_3X} 3x`
  },
  PEAR: {
    src: PEAR_1X,
    srcSet: `${PEAR_1X}, ${PEAR_2X} 2x, ${PEAR_3X} 3x`
  },
  RECTANGLE: {
    src: RECTANGLE_1X,
    srcSet: `${RECTANGLE_1X}, ${RECTANGLE_2X} 2x, ${RECTANGLE_3X} 3x`
  },
  TRIANGLE: {
    src: TRIANGLE_1X,
    srcSet: `${TRIANGLE_1X}, ${TRIANGLE_2X} 2x, ${TRIANGLE_3X} 3x`
  },
  BUDGET: {
    src: BUDGET_1X,
    srcSet: `${BUDGET_1X}, ${BUDGET_2X} 2x, ${BUDGET_3X} 3x`
  },
  MORNING: {
    src: MORNING_1X,
    srcSet: `${MORNING_1X}, ${MORNING_2X} 2x, ${MORNING_3X} 3x`
  },
  SHOPPING: {
    src: SHOPPING_1X,
    srcSet: `${SHOPPING_1X}, ${SHOPPING_2X} 2x, ${SHOPPING_3X} 3x`
  },
  STYLE: {
    src: STYLE_1X,
    srcSet: `${STYLE_1X}, ${STYLE_2X} 2x, ${STYLE_3X} 3x`
  },

  WORK_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.WORK_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.WORK_1X}, ${STRUGGLE_PICKING_LIGHT.WORK_2X} 2x, ${STRUGGLE_PICKING_LIGHT.WORK_3X} 3x`
  },
  DATE_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.DATE_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.DATE_1X}, ${STRUGGLE_PICKING_LIGHT.DATE_2X} 2x, ${STRUGGLE_PICKING_LIGHT.DATE_3X} 3x`
  },
  CASUAL_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.CASUAL_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.CASUAL_1X}, ${STRUGGLE_PICKING_LIGHT.CASUAL_2X} 2x, ${STRUGGLE_PICKING_LIGHT.CASUAL_3X} 3x`
  },
  SPECIAL_EVENTS_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.SPECIAL_EVENTS_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.SPECIAL_EVENTS_1X}, ${STRUGGLE_PICKING_LIGHT.SPECIAL_EVENTS_2X} 2x, ${STRUGGLE_PICKING_LIGHT.SPECIAL_EVENTS_3X} 3x`
  },
  VACATION_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.VACATION_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.VACATION_1X}, ${STRUGGLE_PICKING_LIGHT.VACATION_2X} 2x, ${STRUGGLE_PICKING_LIGHT.VACATION_3X} 3x`
  },
  EVERY_DAY_LIGHT: {
    src: STRUGGLE_PICKING_LIGHT.EVERY_DAY_1X,
    srcSet: `${STRUGGLE_PICKING_LIGHT.EVERY_DAY_1X}, ${STRUGGLE_PICKING_LIGHT.EVERY_DAY_2X} 2x, ${STRUGGLE_PICKING_LIGHT.EVERY_DAY_3X} 3x`
  },
  SMALL_NONE_BUTTON: {
    src: SMALL_NONE_BUTTON_1X,
    srcSet: `${SMALL_NONE_BUTTON_1X}, ${SMALL_NONE_BUTTON_2X} 2x, ${SMALL_NONE_BUTTON_3X} 3x`
  },
  SMALL_OTHER_BUTTON: {
    src: SMALL_OTHER_BUTTON_1X,
    srcSet: `${SMALL_OTHER_BUTTON_1X}, ${SMALL_OTHER_BUTTON_2X} 2x, ${SMALL_OTHER_BUTTON_3X} 3x`
  },

  JEANS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.JEANS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.JEANS_1X}, ${MOST_COMFORTABLE_LIGHT.JEANS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.JEANS_3X} 3x`
  },
  SKIRTS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.SKIRTS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.SKIRTS_1X}, ${MOST_COMFORTABLE_LIGHT.SKIRTS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.SKIRTS_3X} 3x`
  },
  SUITS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.SUITS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.SUITS_1X}, ${MOST_COMFORTABLE_LIGHT.SUITS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.SUITS_3X} 3x`
  },
  SHORTS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.SHORTS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.SHORTS_1X}, ${MOST_COMFORTABLE_LIGHT.SHORTS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.SHORTS_3X} 3x`
  },
  LEGGINGS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.LEGGINGS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.LEGGINGS_1X}, ${MOST_COMFORTABLE_LIGHT.LEGGINGS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.LEGGINGS_3X} 3x`
  },
  SWEATPANTS_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.SWEATPANTS_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.SWEATPANTS_1X}, ${MOST_COMFORTABLE_LIGHT.SWEATPANTS_2X} 2x, ${MOST_COMFORTABLE_LIGHT.SWEATPANTS_3X} 3x`
  },
  DRESSES_LIGHT: {
    src: MOST_COMFORTABLE_LIGHT.DRESSES_1X,
    srcSet: `${MOST_COMFORTABLE_LIGHT.DRESSES_1X}, ${MOST_COMFORTABLE_LIGHT.DRESSES_2X} 2x, ${MOST_COMFORTABLE_LIGHT.DRESSES_3X} 3x`
  },

  HOURGLASS_LIGHT: {
    src: BODY_SHAPE_LIGHT.HOURGLASS_1X,
    srcSet: `${BODY_SHAPE_LIGHT.HOURGLASS_1X}, ${BODY_SHAPE_LIGHT.HOURGLASS_2X} 2x, ${BODY_SHAPE_LIGHT.HOURGLASS_3X} 3x`
  },
  BOTTOM_HOURGLASS_LIGHT: {
    src: BODY_SHAPE_LIGHT.BOTTOM_HOURGLASS_1X,
    srcSet: `${BODY_SHAPE_LIGHT.BOTTOM_HOURGLASS_1X}, ${BODY_SHAPE_LIGHT.BOTTOM_HOURGLASS_2X} 2x, ${BODY_SHAPE_LIGHT.BOTTOM_HOURGLASS_3X} 3x`
  },
  TOP_HOURGLASS_LIGHT: {
    src: BODY_SHAPE_LIGHT.TOP_HOURGLASS_1X,
    srcSet: `${BODY_SHAPE_LIGHT.TOP_HOURGLASS_1X}, ${BODY_SHAPE_LIGHT.TOP_HOURGLASS_2X} 2x, ${BODY_SHAPE_LIGHT.TOP_HOURGLASS_3X} 3x`
  },
  SPOON_LIGHT: {
    src: BODY_SHAPE_LIGHT.SPOON_1X,
    srcSet: `${BODY_SHAPE_LIGHT.SPOON_1X}, ${BODY_SHAPE_LIGHT.SPOON_2X} 2x, ${BODY_SHAPE_LIGHT.SPOON_3X} 3x`
  },
  INVERTED_TRIANGLE_LIGHT: {
    src: BODY_SHAPE_LIGHT.INVERTED_TRIANGLE_1X,
    srcSet: `${BODY_SHAPE_LIGHT.INVERTED_TRIANGLE_1X}, ${BODY_SHAPE_LIGHT.INVERTED_TRIANGLE_2X} 2x, ${BODY_SHAPE_LIGHT.INVERTED_TRIANGLE_3X} 3x`
  },
  TRIANGLE_LIGHT: {
    src: BODY_SHAPE_LIGHT.TRIANGLE_1X,
    srcSet: `${BODY_SHAPE_LIGHT.TRIANGLE_1X}, ${BODY_SHAPE_LIGHT.TRIANGLE_2X} 2x, ${BODY_SHAPE_LIGHT.TRIANGLE_3X} 3x`
  },
  RECTANGLE_LIGHT: {
    src: BODY_SHAPE_LIGHT.RECTANGLE_1X,
    srcSet: `${BODY_SHAPE_LIGHT.RECTANGLE_1X}, ${BODY_SHAPE_LIGHT.RECTANGLE_2X} 2x, ${BODY_SHAPE_LIGHT.RECTANGLE_3X} 3x`
  },
};