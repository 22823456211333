export enum QuizStepKind {
  Square_BG ='square_bg',
  Square = 'square',
  Rectangle = 'rectangle',
  Mini = 'mini',
  Bipolar = 'bipolar',
  Numeric = 'numeric',
  Rate = 'rate',
  Oval = 'oval',
}
