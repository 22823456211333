import type { PaymentSystem, SolidgatePayPalOrder } from '@web-solutions/react-billing';
import type { Currency } from '@web-solutions/react-billing/constants';

import type { OrderDetails, PaddleConfig, Purchase } from '@web-solutions/core/interfaces/billing';
import type { UpsaleBasketItem } from '@web-solutions/core/interfaces/upsaleBasket';

import * as TYPES from './types';
import { type ProductDetails, } from './selectors';

export interface OneTimePurchase {
  product_code: string
  payment_service: PaymentSystem,
  currency: Currency,
  id?: number,
}

export type PaymentMethod = 'card' | 'applepay' | 'googlepay' | 'paypal' | 'mercadopago'

export interface BillingState {
  purchased: boolean,
  loading: boolean,
  pending: boolean,
  isSubmitByCard: boolean,
  orderPending: boolean,
  postcode: string,
  paymentSystem: PaymentSystem | null,
  purchase: Purchase | null,
  orderDetails: OrderDetails | null,
  ordersPayPal: Record<string, SolidgatePayPalOrder>,
  paddleConfig: PaddleConfig | null,
  products: ProductDetails[],
  discountEndDate: string | null,
  noFundsOfferEndDate: string | null,
  specialOfferEndDate: string | null,
  paymentMethod: PaymentMethod | null,
  specialOfferProducts: ProductDetails[],
  noFundsOfferProducts: ProductDetails[],
  trialPrice: number | null,
  injectedPaymentMethod: string | null,
  oneTimePurchases: OneTimePurchase[],
  trialsLeftCounter: number,
  countryCode: string | null,
  upsalesBasket: UpsaleBasketItem[],
  oneTimePurchaseId: number | null,
}

export const initialState: BillingState = {
  paymentSystem: null,
  purchase: null,
  purchased: false,
  loading: true,
  pending: false,
  isSubmitByCard: false,
  orderDetails: null,
  ordersPayPal: {},
  orderPending: false,
  paddleConfig: null,
  paymentMethod: null,
  products: [],
  upsalesBasket: [],
  discountEndDate: null,
  noFundsOfferEndDate: null,
  specialOfferEndDate: null,
  specialOfferProducts: [],
  noFundsOfferProducts: [],
  trialPrice: null,
  postcode: '',
  injectedPaymentMethod: null,
  oneTimePurchases: [],
  trialsLeftCounter: 10,
  countryCode: null,
  oneTimePurchaseId: null,
};

const reducer = (state = initialState, action: TYPES.BillingActionTypes) => {

  switch (action.type) {
    case TYPES.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case TYPES.SET_PAYMENT_SYSTEM:
      return {
        ...state,
        paymentSystem: action.payload.paymentSystem,
      };
    case TYPES.SET_ONE_TIME_PURCHASES:
      return {
        ...state,
        oneTimePurchases: action.payload,
      };
    case TYPES.SET_PURCHASE:
      return {
        ...state,
        purchase: action.payload.purchase,
        purchased: !!action.payload.purchase,
      };

    case TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_IS_SUBMIT_BY_CARD:
      return {
        ...state,
        isSubmitByCard: action.payload,
      };

    case TYPES.SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };

    case TYPES.SET_ORDERS_PAYPAL:
      return {
        ...state,
        ordersPayPal: {
          ...state.ordersPayPal,
          ...action.payload,
        },
      };

    case TYPES.SET_ORDER_PENDING:
      return {
        ...state,
        orderPending: action.payload,
      };

    case TYPES.SET_PADDLE_CONFIG:
      return {
        ...state,
        paddleConfig: action.payload,
      };

    case TYPES.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case TYPES.SET_DISCOUNT_END_DATE:
      return {
        ...state,
        discountEndDate: action.payload,
      };

    case TYPES.SET_NO_FUNDS_END_DATE:
      return {
        ...state,
        noFundsOfferEndDate: action.payload,
      };

    case TYPES.SET_SPECIAL_OFFER_END_DATE:
      return {
        ...state,
        specialOfferEndDate: action.payload,
      };

    case TYPES.SET_SPECIAL_OFFER_PRODUCTS:
      return {
        ...state,
        specialOfferProducts: action.payload,
      };

    case TYPES.SET_NO_FUNDS_OFFER_PRODUCTS:
      return {
        ...state,
        noFundsOfferProducts: action.payload,
      };
      
    case TYPES.SET_TRIAL_PRICE:
      return {
        ...state,
        trialPrice: action.payload,
      };

    case TYPES.SET_POSTCODE:
      return {
        ...state,
        postcode: action.payload,
      };

    case TYPES.RESET:
      return {
        ...initialState,
      };

    case TYPES.SET_INJECTED_PAYMENT_METHOD:
      return {
        ...state,
        injectedPaymentMethod: action.payload,
      };

    case TYPES.SET_TRIALS_LEFT_COUNTER:
      return {
        ...state,
        trialsLeftCounter: state.trialsLeftCounter - 1,
      };

    case TYPES.SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload
      }

    case TYPES.SET_UPSALE_TO_BASKET: {
      return {
        ...state,
        upsalesBasket: [...state.upsalesBasket, action.payload].sort((a, b) => Number(b.checked) - Number(a.checked))
      }
    }

    case TYPES.UPDATE_UPSALE_IN_BASKET: {
      return {
        ...state,
        upsalesBasket: state.upsalesBasket.map((upsale) => upsale.id === action.payload.id ? { ...upsale, ...action.payload } : upsale)
      }
    }
    case TYPES.SET_ONE_TIME_PURCHASE_ID: {
      return {
        ...state,
        oneTimePurchaseId: action.payload
      }
    }

    default:
      return state;
  }
};

reducer.whitelist = [
  'subscriptionDetails',
  'purchase',
  'purchased',
  'discountEndDate',
  'paymentMethod',
  'specialOfferEndDate',
  'noFundsOfferEndDate',
  'trialPrice',
  'paddleConfig',
  'postcode'
];

export default reducer;
